import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Tagline from "../components/tagline"
import SEO from "../components/seo"

export default () => (
  <Layout>
    <SEO title="About Aj" />
    <h1>About us</h1>
    <Tagline />

    <h2>Affiliate links</h2>
    <p>
      Some of our content includes links to purchase items, in which case we may
      earn a commission. However, links are only included when we believe the
      item is worth purchasing. If we review an item that is not satisfactory
      our intention is to let you know that you should not purchase the item and
      we will not include links to earn a commission.
    </p>

    <h2>Policies</h2>
    <p>
      <Link to="/policy">Privacy Policy</Link>
    </p>
  </Layout>
)
